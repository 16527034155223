import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import moment from 'moment/moment';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageEnum } from '../../../services/api/models/language-enum';
import { PermissionName } from '../../../services/api/models/permission-name';
import { UserAlertNotificationSetting } from '../../../services/api/models/user-alert-notification-setting';
import { UserAlertNotificationSettingDto } from '../../../services/api/models/user-alert-notification-setting-dto';
import { UserAlertNotificationSettingService } from '../../../services/api/services/user-alert-notification-setting.service';
import { CustomerSelectorsService } from '../../../services/customer-selectors/customer-selectors.service';
import { AvatarService } from '../../../services/reserved-services/avatar.service';
import { PermissionService } from '../../../services/user/permission-service';
import { DATE_FORMAT_WITH_Z } from '../../../services/utils/utils.service';

export interface IProfile {
  customer: string;
  role: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  id?: number;
  language: LanguageEnum;
}

export enum ProfileTabs {
  PersonalDetails = 'Personal_Details',
  ResetPassword = 'Reset_Password',
  Language = 'Language',
  Notifications = 'Notifications',
  Timezone = 'Timezone',
}

@Component({
  selector: 'profile-modal',
  templateUrl: 'profile-modal.component.html',
  styleUrls: ['profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit, OnDestroy {
  @Output() public onCloseModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() public profileData: IProfile;
  public showSaveFail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isChangePassword: boolean = false;
  public firstName: string = '';
  public lastName: string = '';
  public language: LanguageEnum = LanguageEnum.EnUs;
  public phone: string = '';
  public passwordCurrent: string = '';
  public passwordNew: string = '';
  public passwordConfirm: string = '';
  public errorMessage: string = '';
  private destroyed$ = new Subject();

  public settingsUpdated$: Subject<boolean> = new Subject<boolean>();

  //PERMISSIONS
  public notificationSettingUpdate: PermissionName =
    PermissionName.UserNotificationSettingUpdate;

  public ProfileTabs = ProfileTabs;

  public selectedTab: ProfileTabs = ProfileTabs.PersonalDetails;

  public isEditUser: boolean = true;

  public settings: UserAlertNotificationSetting[];

  public avatarUrl: string;

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  public file: any = null;

  constructor(
    public permissionService: PermissionService,
    public userAlertNotificationSettingService: UserAlertNotificationSettingService,
    public avatarService: AvatarService,
    public customerSelectorsService: CustomerSelectorsService
  ) {}

  public ngOnInit() {
    this.firstName = this.profileData.firstName;
    this.lastName = this.profileData.lastName;
    this.phone = this.profileData.phone;
    this.language = this.profileData.language;
    this.loadAvatar();
  }

  public closeModal() {
    this.onCloseModal.emit(true);
  }

  public async selectTab(tab: ProfileTabs) {
    this.selectedTab = tab;

    switch (this.selectedTab) {
      case ProfileTabs.Notifications:
        await this.getUserNotificationSettings();
      case ProfileTabs.PersonalDetails:
        this.isEditUser = true;
    }
  }

  public onEditRole(settings: any) {
    this.isEditUser = false;
  }

  public async getUserNotificationSettings() {
    await this.userAlertNotificationSettingService
      .findByRoleId_1({
        userId: this.profileData.id,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.setDateForTimePicker(res);
        this.settings = res;
        this.isEditUser = true;
      });
  }

  public setDateForTimePicker(res: UserAlertNotificationSetting[]) {
    res.forEach((item) => {
      item.timeFrom = item.timeFrom
        ? moment.utc(item.timeFrom).format(DATE_FORMAT_WITH_Z)
        : null;
      item.timeTo = item.timeTo
        ? moment.utc(item.timeTo).format(DATE_FORMAT_WITH_Z)
        : null;
    });
  }

  public async addSetting(event) {
    event.body.userId = this.profileData.id;
    return this.userAlertNotificationSettingService
      .addSetting_2({
        body: event.body as UserAlertNotificationSettingDto,
      })
      .toPromise();
  }

  public async updateSetting(event) {
    const payload: {
      settingId?: number;
      body: UserAlertNotificationSettingDto;
    }[] = event;
    await Promise.all(
      payload.map(async (item) => {
        item.body.userId = this.profileData.id;
        if (item.settingId) {
          await this.userAlertNotificationSettingService
            .updateSetting_4({
              settingId: item.settingId,
              body: item.body,
            })
            .toPromise();
        } else {
          await this.addSetting(item);
        }
      })
    );
    this.settingsUpdated$.next(true);
  }

  public fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  public prepareFilesList(file: any) {
    this.file = file;
    this.fileDropEl.nativeElement.value = '';

    this.uploadFile();
  }

  public loadAvatar() {
    this.avatarService
      .getUserAvatar({
        userId: this.profileData.id,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        this.avatarUrl = JSON.parse(res).avatarUrl;
      });
  }

  public uploadFile() {
    const formData: FormData = new FormData() as any;

    formData.append('file', this.file, this.file.name);

    this.avatarService
      .updateUserAvatar({
        userId: this.profileData.id,
        body: formData,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        this.loadAvatar();
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
